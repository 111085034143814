import React from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import TournamentSettings from 'src/components/tournaments-settings/tournaments-settings';
import { usePrivateRoute } from 'src/utils/auth';

const TournamentSettingsPage: React.FC = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Tournament Settings" />
      <TournamentSettings />
    </Layout>
  );
};

export default TournamentSettingsPage;
