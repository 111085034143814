import React, { ChangeEvent } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import * as styles from './radio-button-group.module.less';

export type Option = {
  value: string;
  label: string;
};

type Props = {
  options: Option[];
  isRow?: boolean;
  props?: any;
  value: string;
  label?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  field?: {
    name: string;
    value: string;
  };
};

const RadioButtonGroup: React.FC<Props> = ({ options, value, label, isRow, handleChange, field, ...props }: Props) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup aria-label={value} value={value} onChange={handleChange} row={isRow} {...field} {...props}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            classes={{ label: styles.typography }}
            control={
              <Radio
                color="default"
                classes={{
                  root: styles.radioButton,
                  checked: styles.checked,
                  colorSecondary: styles.radioButton,
                }}
                disableRipple
                className={styles.radioButton}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
