import graphql from 'graphql-tag';

const gql = graphql;

export const GET_ORG_URL_SEGMENT = gql`
  query GetOrgUrlSegment($orgId: UUID!) {
    organisation(id: $orgId) {
      id
      urlSegment
    }
  }
`;

export const GENERATE_NEW_ACCOUNT_URL = graphql`
  mutation GenerateNewAccountUrl($accountIntegration: AccountIntegrationInput!) {
    integrationUrl(accountIntegration: $accountIntegration)
  }
`;

export const GET_DEFAULT_ACCOUNT = graphql`
  query GetDefaultAccount($orgId: String!) {
    venueDefaultAccount(venueId: $orgId) {
      userId
      firstName
      lastName
      created
      phone
      email
      accountId
      status {
        chargesEnabled
        payoutsEnabled
      }
    }
  }
`;

export const GET_ACCOUNT_LOGIN_LINK = graphql`
  query GetDefaultAccountLoginLink($orgId: String!) {
    venueDefaultAccount(venueId: $orgId) {
      loginLink
    }
  }
`;
